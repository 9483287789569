<template>
    <div>
        <div @click="navigateTo('/login')" class="back"></div>
        <div class="title">使用手机号码进行注册</div>

        <div class="input-box"><input placeholder="请输入您的手机号" type="text" v-model="mobile"/></div>
        <div class="input-box vcode">
            <input placeholder="请输入验证码" type="text" v-model="vcode"/>
            <div @click="sendVcode" v-if="seconds == 0">发送验证码</div>
            <div style="color: #C3C3C3;" v-else>发送验证码({{seconds}})</div>
        </div>
        <div class="input-box"><input placeholder="请输入6～16位数字或字符密码" type="password" v-model="password"/></div>
        <div class="input-box"><input placeholder="请确认新密码" type="password" v-model="confirmPassword"/></div>
        <div class="input-box" v-if="!parentCode"><input placeholder="请输入邀请人手机号码" type="text" v-model="parentMobile"/>
        </div>
        <div @click="register" class="button active" v-if="mobile && vcode && password && confirmPassword">注册</div>
        <div class="button" v-else>注册</div>
        <div class="link-box">
            <div @click="navigateTo('/login')">已有账号，去登录</div>
        </div>
    </div>
</template>

<script>
    import Cookies from 'js-cookie'
    import {apiPost} from '@/utils/api.js'

    export default {

        data() {
            return {
                mobile: '',
                password: '',
                confirmPassword: '',
                vcode: '',
                parentMobile: '',
                parentCode: '',
                seconds: 0,
            }
        },

        created() {
            if (this.$route.query.code) Cookies.set('invite_code', this.$route.query.code)

            this.parentCode = Cookies.get('invite_code')
        },

        destroyed() {
            if (this.secondTimer) clearInterval(this.secondTimer)
        },

        methods: {
            register() {
                if (this.password && this.password != this.confirmPassword) {
                    this.$toast('两次密码输入不一致')
                    return;
                }

                if (this.mobile && this.password) {
                    apiPost('/auth/register', {
                        mobile: this.mobile,
                        password: this.password,
                        vcode: this.vcode,
                        parentMobile: this.parentMobile,
                        parentCode: this.parentCode
                    }).then(result => {
                        if (result.code == 200) this.navigateTo('/');
                    })
                }
            },
            sendVcode() {
                if (this.mobile) {
                    apiPost('/auth/vcode', {mobile: this.mobile}).then(result => {
                        if (result.code == 200) {
                            this.seconds = 60;
                            this.secondTimer = setInterval(() => {
                                if (this.seconds) this.seconds -= 1;
                                if (!this.seconds) {
                                    clearInterval(this.secondTimer);
                                    this.secondTimer = null;
                                }
                            }, 1000)
                        }
                    })
                } else {
                    this.$toast('请输入手机号')
                }
            },

            navigateTo(url) {
                this.$router.push(url)
            },
        }
    }
</script>


<style scoped>
    .back {
        position: absolute;
        top: 0.50rem;
        left: 0.40rem;
        width: 0.35rem;
        height: 0.35rem;
        opacity: 1;
        background: url("../../assets/back.svg");
        background-size: cover;
    }

    .title {
        margin-top: 2.70rem;
        margin-left: 0.50rem;
        line-height: 0.40rem;
        font-size: 0.40rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Medium;
        font-weight: 500;
        color: #444444;
        margin-bottom: 0.80rem;
    }

    .input-box {
        padding: 0.45rem 0.20rem;
        width: 6.90rem;
        margin: 0 auto;
        border-bottom: 0.01rem solid #f6f6f6;
        box-sizing: border-box;
        font-size: 0.28rem;
    }

    .input-box input {
        width: 6.50rem;
        border: none;
        outline: none;
    }

    .input-box.vcode {
        display: flex;
        justify-content: space-between;
    }

    .input-box.vcode input {
        width: 3.50rem;
    }

    .input-box.vcode div {
        color: #0091ff;
    }

    .button {
        margin: 1.00rem auto 0;
        width: 6.50rem;
        line-height: 0.84rem;
        background: #f9c3ac;
        border-radius: 0.10rem;

        font-size: 0.28rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
    }

    .button.active {
        background: #FE7C45;
    }

    .link-box {
        display: flex;
        justify-content: space-around;
        margin-top: 0.40rem;
        padding: 0 0.60rem;
    }

    .link-box div {
        font-size: 0.26rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: center;
        color: #0091ff;
        line-height: 0.35rem;
    }
</style>

